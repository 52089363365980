/* global DMP_JS_PROPS */

const dmpSearch = {
  clearSearchSuggestions: (event) => {
    const form = event.target.closest('.search');
    const list = form.querySelector('.js-results-list');
    form.classList.remove('show-results');
    list.innerHTML = '';
  },
  getSearchSuggestions: (event) => {
    const form = event.target.closest('.search');
    if (form === null) {
      return;
    }
    const resultsList = form.querySelector('.js-results-list');
    const query = event.target.value;
    if (!query || (query && query.length < 3) || !resultsList) {
      if (resultsList) {
        dmpSearch.clearSearchSuggestions(event);
      }
      // Ignore queries shorter than three characters
      return;
    }

    //   TODO normalize/sanitize query here?
    const queryUrl = `${DMP_JS_PROPS.URLS.SEARCHSUGGESTIONS}?query=${query}`;
    fetch(queryUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dmpSearch.clearSearchSuggestions(event);
        if (!data) {
          return;
        }
        const searchResults = data.results;
        if (searchResults.length > 0) {
          form.classList.add('show-results');
          searchResults.map((result) => {
            const el = document.createElement('li');
            const linkEl = document.createElement('a');
            linkEl.setAttribute('href', result.url);
            linkEl.innerHTML = result.title;
            el.appendChild(linkEl);
            return resultsList.appendChild(el);
          });
        }
      });
  },
  hasSearchResults: (formEl) => {
    const resultsList = formEl.querySelector('.js-results-list');
    if (resultsList !== null) {
      return resultsList.childElementCount > 0;
    }
    return false;
  },
  hideSearchResults: (formEl) => {
    if (formEl) {
      formEl.classList.remove('show-results');
    }
  },
  navigateSearchResults: (event) => {
    const form = event.target.closest('.search');
    if (form === null) {
      return;
    }

    const searchResults = form.querySelector('.js-results-list');
    const searchInput = form.querySelector('.js-search-input');

    if (dmpSearch.hasSearchResults(form)) {
      const first = searchResults.firstElementChild.firstElementChild;
      const last = searchResults.lastElementChild.lastElementChild;

      switch (event.key) {
        case 'ArrowUp':
          /**
           * Using keydown and preventDefault to prevent the window from
           * vertically scrolling as we navigate through the search results.
           * However, we only want to prevent the default if the up or down
           * arrow is pressed, which is why e.preventDefault is only executed
           * within the specific cases.
           */
          event.preventDefault();
          if (document.activeElement === searchInput) {
            // Focus the last element if the input field is focused.
            // Basically make the search result navigation loop.
            last.focus();
          } else if (document.activeElement === first) {
            // Focus the input field if the first element is focused.
            searchInput.focus();
          } else {
            // Focus the previous search result entry.
            document.activeElement.parentNode.previousSibling.firstElementChild.focus();
          }
          break;
        case 'ArrowDown':
          event.preventDefault();
          if (document.activeElement === searchInput) {
            first.focus();
          } else if (document.activeElement === last) {
            searchInput.focus();
          } else {
            document.activeElement.parentNode.nextSibling.firstElementChild.focus();
          }
          break;
        case 'Escape':
          event.preventDefault();
          dmpSearch.clearSearchSuggestions(event);
          break;
        default:
        // Do nothing by default
      }
    }
  },
  showSearchResults: (formEl) => {
    const activeForms = document.querySelectorAll(
      '.js-search-suggestions.show-results',
    );
    for (let i = 0; i < activeForms.length; i += 1) {
      dmpSearch.hideSearchResults(activeForms[i]);
    }
    if (formEl && dmpSearch.hasSearchResults(formEl)) {
      formEl.classList.add('show-results');
    }
  },
  toggleSearch: () => {
    if (document.body.classList.contains('site-search-open')) {
      document.body.classList.remove('site-search-open');
    } else {
      document.body.classList.add('site-search-open');
      // Focus on the search input field after opening the site search
      const searchInput = document.querySelector('.js-search-input');
      if (searchInput) {
        searchInput.focus();
      }
    }
  },

};

export default dmpSearch;
